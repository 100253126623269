.infoBlock {
  position: relative;
}

.container {
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font-size: 0.875rem;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #212121;
}

.danger {
  background: rgba(219, 0, 0, 0.1);
  border: 1px solid #db0042;
}

.closeIcon {
  position: absolute;
  width: 42px !important;
  right: 0;
}

.info {
  background: rgba(0, 152, 219, 0.1);
  border: 1px solid #0098DB;
}

.attention {
  background: #FF950014;
  border: 1px solid #FF9500;
}

.success {
  background: rgba(34, 211, 122, 0.1);
  border: 1px solid #59B783;
}