.timerNew {
  .timeLeft {
    display: flex;
    align-items: center;
    gap: 4px;

    &.vertical {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .timer {
        width: unset;
      }
    }
  }
  
  .mSec {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  
  .timer {
    color: #EF3124;
    width: 64px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%
  }
}