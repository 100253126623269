.spinner {
  width: 100vw;
  height: 10vh;
  margin: auto;
}

.description {
  align-self: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 36px */

  @media (max-width: 480px) {
    font-size: 20px;
  }
}