.steps {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.stepsContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.stepsContent {
  width: 100%;
}

.step {
  height: 2px;
  margin: unset;
  content: '';
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
}

.active {
  background-color: #0098DB;
}

.spinner {
  size: 20vh;
  margin: auto;
}