.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  align-self: stretch;

  .maskContainer {
    width: 100%;

    .text {
      display: block;
      margin-bottom: 16px;
      margin-top: 16px;
      text-align: center;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  .headerText {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 36px */

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .brokers {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;

    .broker {
      width: 100%;
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100px;
      min-width: 146px;
      max-height: 111px;
      gap: 8px;
      flex: 0;
      cursor: pointer;

      border-radius: 8px;
      background: #fff;
      color: #8b959e;

      span {
        font-size: 16px;
        text-align: center;
      }

      &:hover {
        box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.24);
        color: #000;
        font-size: 12px;
      }

      @media (max-width: 768px) {
        min-width: 102px;
        max-height: 100px;
        height: 100px;
        padding: 8px;
        img {
          width: 50px !important;
          height: 50px !important;
          object-fit: contain;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}
