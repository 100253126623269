.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  .paymentId {
    display: flex;
    flex-direction: row;
    gap: 16px;
    color: #8b959e;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .headerText {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 36px */
  }

  .paymentText {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 36px */
  }

  .infoContainer {
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    width: 100%;

    a {
      text-decoration: none;
      color: #00b3fb;
    }
  }

  .waiting {
    .tip {
      font-size: 18px;
      color: #00b3fb;
    }
  }

  .tabs {
    @media (max-width: 768px) {
      width: calc(100vw - 70px);
      max-width: calc(100vw - 70px);
    }
  }

  .ok {
    .voteActions {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .dispute {
    gap: 16px;
  }

  .title {
    font-size: 30px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .details {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    padding: 24px;
    background: #fff;
    box-shadow: 2px 3px 32px 0px rgba(168, 184, 200, 0.32);
  }

  .openChatContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px;
  }

  .tabsInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 0px 0px 8px 8px;
    background: #fff;
    padding: 24px;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .name {
        font-size: 18px;
        color: #8b959e;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }

      .value {
        font-size: 18px;
        word-break: break-all;
        text-align: end;

        @media (max-width: 768px) {
          font-size: 14px;
        }

        &.highlighted {
          color: #00b3fb;
        }

        a {
          text-decoration: none;
          color: #00b3fb;
        }
      }
    }
  }
}
