.payCard {
  display: flex;
  width: 352px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  background: #FFF;
  box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.24);

  justify-content: space-between;

  @media (max-width: 800px) {
    width: 100%;
    min-width: 312px;
  }

  .requisitesBlock {
    width: 100%;
  }

  .requisitesText {
    color: #00B3FB;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    gap: 8px;
  }

  .fieldName {
    display: block;
    color: #757474;
    margin-top: 8px;
  }

  .broker {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .timeLeft {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .mSec {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .timer {
    color: #EF3124;
    width: 64px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%
  }

  .editable {
    border: unset;
    height: unset;
    width: 100%;
  }

  .info {
    display: flex;
    padding: 16px 16px;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #F3F6FA;
    height: fit-content;

    &.editable {
      justify-content: space-between;
    }
  }

  .requisite {
    color: #1D1D1D;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    width: 100%;
    line-height: 120%; /* 28.8px */
    text-transform: uppercase;
    word-break: break-word;
  }

  .copyIcon {
    min-width: 24px;
    cursor: pointer;
  }

  .hint {
    padding-top: 8px;
    font-size: 12px;
  }
}





