.wrapper {
  display: flex;
  background-color: #F3F4F5;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  justify-content: center;
}

.container {
  position: relative;
  font-family: Euclid Circular B;
  display: flex;
  width: 800px;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.lang {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 60px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.initContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  align-self: stretch;
}

.paymentContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  align-self: stretch;
}

.processedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.headerText {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 36px */
}

.paymentText {
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 36px */
}

.brokersContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.brokerContainer {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 152px;
  max-height: 140px;
  gap: 26px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid #0000003d;
  background: #fff;
}

.paymentBlock {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.hints {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.numeratedHints {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.numeratedHint {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.hintNumber {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #8b959e;
  color: #8b959e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.hintText {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.additionalHints {
  align-self: stretch;
  padding-left: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.paymentId {
  display: flex;
  flex-direction: row;
  gap: 16px;
  color: #8b959e;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.id {
  color: #00b3fb;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.logo {
  width: 280px;
}

.footer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.footerBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #8b959e;
  text-decoration: unset;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.footerIcon {
  width: 32px;
}

.maskContainer {
  width: 100%;
}

.maskText {
  display: block;
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: center;
}

.maskButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media (max-width: 800px) {
  .container {
    width: 100vw;
    padding: 16px;
  }

  .headerText,
  .paymentText {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .logo {
    width: 180px;
  }

  .paymentBlock {
    flex-direction: column;
  }

  .hints {
    padding: 0px 0px;
  }

  .footer {
    align-items: flex-end;
    justify-content: center;
  }

  .footerBlock {
    flex-direction: column;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}

.warning {
  display: block;
  color: #ef3124;
  margin: 16px 0;
}
