.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 2px 3px 32px 0px rgba(168, 184, 200, 0.32);

  @media (max-width: 768px) {
    min-width: 100%;
    width: auto;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .mobileTab {
      display: flex;
      flex-direction: column;
    }

    .tab {
      display: flex;
      flex-direction: row;
      padding: 0 16px;
      flex-grow: 1;
      height: 56px;
      align-items: center;
      gap: 16px;
      text-transform: uppercase;
      cursor: pointer;

      background-color: #f3f6fa;
      filter: grayscale(1);

      &.noGrayscale {
        filter: grayscale(0);
      }

      &.centered {
        justify-content: center;
      }

      @media (max-width: 1024px) {
        width: 100%;
      }

      .icon {
        width: 24px;
        height: 24px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .caption {
        font-size: 16px;
      }

      &.active {
        background-color: #fff;
        border-bottom: 2px solid #00b3fb;
        filter: grayscale(0);
      }
    }
  }
}
