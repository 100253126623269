.formItem {
  display: flex;
  gap: 8px;
}

.inline {
  flex-direction: row;
}

.block {
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}