html,
body {
  height: 100%;
  /*position: fixed;*/
}
html {
  overflow: hidden;
}
body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  background-color: #f1f1f1 !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #00b3fb;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
