.wait {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  text-align: center;

  @media (max-width: 768px) {
    gap: 16px;
  }

  .title {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .hint {
    color: #00B3FB;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .icons {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    @media (max-width: 768px) {
      gap: 12px;
    }

    .tip {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      flex-basis: 100%;

      @media (max-width: 768px) {
        padding: 4px;
      }

      .icon {
        font-size: 78px;
        padding: 4px;
        font-style: normal;
        font-weight: 500;

        @media (max-width: 768px) {
          font-size: 40px;
        }
      }

      .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #1D1D1D;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
}

.receiptModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  gap: 8px;

  &, .timer, .attachReceipt {
    font-size: 18px;
  }

  .receiptIcon {
    font-size: 78px;
    font-style: normal;
    font-weight: 500;
  }

  .attachReceipt {
    cursor: pointer;
    color: #0097db;
  }

  .receiptTip {
    background-color: #e8ecf1e3;
    border-radius: 16px;
    padding: 16px;
    max-width: 500px;
  }

  .timer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  align-self: stretch;

  .headerText {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 36px */

    @media (max-width: 1024px) {
      font-size: 24px;
    }

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .tabs {
    @media (max-width: 768px) {
      width: calc(100vw - 70px);
      max-width: calc(100vw - 70px);
    }
  }

  .openChatContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px;
  }

  .warning {
    display: block;
    color: #EF3124;
    margin: 16px 0;
  }  

  .paymentBlock {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .hints {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      flex: 1 1 0px;    
      align-self: stretch;

      .numeratedHints {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
      }
      
      .numeratedHint {
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
      }
      
      .hintNumber {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        border: 1px solid #8b959e;
        color: #8b959e;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
      
      .hintText {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      
      .additionalHints {
        align-self: stretch;
        padding-left: 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
      
      .paymentId {
        display: flex;
        flex-direction: row;
        gap: 16px;
        color: #8b959e;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
      
      .id {
        color: #00b3fb;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }  
    
      .support {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;

        @media (max-width: 768px) {
          font-size: 14px;
        }
    
        a {
          text-decoration: none;
          color: #00B3FB;
        }
      }
    
      .actions {
        width: 100%;
    
        @media (max-width: 1280px) {
          flex-direction: column;
        }

        @media (max-width: 480px) { 
          justify-content: center; 
        }
    
        .action {
          flex: 1 1 0;
    
          @media (max-width: 1280px) {
            flex: 1 1 auto;
            width: 100%;
            // flex-basis: 100%;
          }
        }
      }
    }
  }
}