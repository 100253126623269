.cryptoPay {
  padding-top: 20px;
  /* width: calc(100vw - 20px); */
  overflow-x: hidden;
  overflow-y: hidden;
  overscroll-behavior: none;
}

.cryptoInit {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.currencyIcon {
  height: calc(5.5vh - 10px) !important;
}

.emailInput {
  border-width: 1px;
}

.submitButton {
  color: #FFFFFF;
  text-transform: uppercase;
}

.cryptoSelect {
  border: 2px solid rgba(0, 0, 0, 0.24);
}

.highlighted {
  color: #DB3E36;
}

.titled {
  font-weight: 600;
}

.container {
  width: 480px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.amountContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  color: #0098DB;
}

.amountSymbol {
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #8B959E;
  text-transform: uppercase;
}

.amountValue {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.amountTip {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #1d1d1d;
}

.cryptoInProcess {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.walletLabel {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
}

.paymentInfo > * {
  display: block;
}

.paymentInfo {
  display: flex;
  flex-direction: column;
}

.copied {
  visibility: hidden;
  border-radius: 100px;
  padding: 4px 8px;
  background: rgba(0, 152, 219, 0.1);
  color: #0098DB;
}

.copyContainer {
}

.copied.displayed {
  visibility: visible;
}

.walletAddress {
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: unset;
  width: 100%;
  padding: 0 8px;
  line-height: 3rem;
  color: #0098DB;
  background-color: #F8F8F8;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.timeLeft {
  font-weight: 500;
  font-size: 20px
}

.info {
  color: #0098DB;
}

.danger {
  color: #db0042;
}

.qrContainer {
  margin-right: auto;
  margin-left: auto;
}

.card {
  width: 100%;
}

@media (max-width: 480px) {
  .container {
    padding: 16px 16px;
    width: 100%;
  }

  .card {
    padding: 0;
  }
}