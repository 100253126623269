.container {
  position: relative;
  font-family: Euclid Circular B;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vh 10vw;
  gap: 16px;
  width: 70vw;
  max-width: 1000px;

  @media (max-width: 768px) {
    margin: unset;
    width: 100%;
    padding: 16px;
    gap: 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    @media (max-width: 1280px) {
      display: grid;
      grid-template-columns: 3fr 1fr;
    }

    .logo {
      width: 160px;

      @media (max-width: 1280px) {
        width: 114px;
      }
    }

    .lang {
      margin-top: auto;
      margin-bottom: auto;

      @media (max-width: 1280px) {
        justify-self: end;
        order: 2;
      }
    }

    .title {
      font-size: 33px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 39.6px */
      color: #00b3fb;

      @media (max-width: 1280px) {
        order: 3;
        font-size: 18px;
      }
    }
  }

  .stepsContainer {
    border-radius: 16px;
    background: #f3f4f5;
    padding: 40px;
    width: 100%;

    @media (max-width: 768px) {
      padding: 16px;
    }
  }

  .hints {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .hintTitle {
      font-size: 18px;
      color: #00b3fb;
      cursor: pointer;

      @media (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        gap: 8px;

        &::before {
          content: '•';
        }
      }

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding-top: 16px;

    .block {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      color: #8b959e;
      text-decoration: unset;
      text-transform: uppercase;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      svg {
        height: 32px;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        font-size: 12px;
        word-break: keep-all;
      }

      &:hover {
        color: #000;

        .youtube {
          path:first-of-type {
            fill: #fc0d1c !important;
          }
        }

        .tg {
          path:first-of-type {
            fill: #00b3fb !important;
          }
        }
      }
    }

    .icon {
      width: 32px;
    }
  }
}

.orderDetailsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  .merchantInfo {
    display: flex;
    flex-direction: row;
    gap: 16px;

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.24);
    }

    .amount {
      font-size: 40px;
      font-weight: 600;

      .currency {
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 18px;

    @media (max-width: 1280px) {
      font-size: 14px;
    }

    .highlighted {
      color: #00b3fb;
    }
  }

  .merchantInfo {
  }
}
