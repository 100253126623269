.languageSelector {
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: flex-start;
}

.languagePicker {
  padding-left: 8px;
}

.langOption {
  padding-left: 12px !important;
  border-color: rgba(0, 0, 0, 0.24) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.24);
  border-left: 1px solid rgba(0, 0, 0, 0.24);
}

@media (max-width: 800px) {
  .languageSelector {
    width: 100%;
    min-width: 80px;
  }
}
