.info {
  display: flex;
  padding: 16px 16px;
  margin-top: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #F3F6FA;
  height: fit-content;

  .requisite {
    color: #1D1D1D;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 28.8px */
    text-transform: uppercase;
    word-break: break-word;
  }
}

